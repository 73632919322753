import {Country} from "./country";
import {CountryModelData} from "desiren-core-lib/lib/types/countries";

export interface OnboardingInterface {
  id: string | null;
  bio: string | null;
  userId: string | null;
  residenceCountry: CountryModelData | null;
  attachments: Array<OnboardingAttachmentInterface>;
}

export interface OnboardingAttachmentInterface {
  id: string;
  filename: string;
  url: string;
  onboardingId: string;
  imageType: 'avatar' | 'background'
}

export class OnboardingData implements OnboardingInterface {
  attachments: Array<OnboardingAttachment>;
  bio: string | null;
  residenceCountry: Country | null;
  id: string | null;
  userId: string | null;
  constructor(data: OnboardingInterface) {
    this.attachments = data.attachments.map(i => {return new OnboardingAttachment(i)});
    this.bio = data.bio;
    this.residenceCountry = data.residenceCountry != null ? new Country(data.residenceCountry!) : null;
    this.id = data.id;
    this.userId = data.userId;
  }
  get avatar(): OnboardingAttachment | null {
    return this.attachments.find((e,i,a) => {
      return e.imageType == 'avatar';
    }) ?? null;
  }
  get background(): OnboardingAttachment | null {
    return this.attachments.find((e,i,a) => {
      return e.imageType == 'background'
    }) ?? null;
  }
  toJson() {
    return {
      'attachments': this.attachments.map(e => e.toJson()),
      'bio': this.bio,
      'residenceCountry': this.residenceCountry?.toJson(),
      'id': this.id,
      'userId': this.userId,
    }
  }
}

export class OnboardingSendData {
  bio: string;  countryId: string; avatar: Blob; background: Blob;
  constructor(bio: string,  countryId: string, avatar: Blob, background: Blob) {
    this.bio = bio.replaceAll('\n', '<br/>');
    this.countryId = countryId;
    this.avatar = avatar;
    this.background = background;
  }
}

export class OnboardingAttachment implements OnboardingAttachmentInterface {
  filename: string;
  id: string;
  imageType: "avatar" | "background";
  onboardingId: string;
  url: string;
  constructor(data: OnboardingAttachmentInterface) {
    this.filename = data.filename;
    this.id = data.id;
    this.imageType = data.imageType;
    this.onboardingId = data.onboardingId;
    this.url = data.url;
  }
  toJson() {
    return {
      'filename': this.filename,
      'id': this.id,
      'imageType': this.imageType,
      'onboardingId': this.onboardingId,
      'url': this.url,
    }
  }
}
